const userRoleId = localStorage.getItem("userRoleId");

export function isDsplayAddProduct() {
  if (userRoleId === "1" || userRoleId === "2" || userRoleId === "3")
    return true;
  return false;
}

export function isStoreLogin() {
  if (userRoleId === "4" || userRoleId === "8") return true;

  return false;
}
