import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { changeStatus, loadingStatus } from "../../redux/slice/loaderSlice";
import { checkUserAccess } from "../../network/service/UserService";

const SidebarLink = ({ item, isMenuExpand, activeMenu, selectedMenu }) => {
  const dispatch = useDispatch();
  const subnav = useSelector(loadingStatus);

  const showSubNav = (value) => {
    // setSubnav(value);
    dispatch(changeStatus(value));
  };

  return (
    <>
      {item.path === "" ? (
        <NavLink
          className={
            activeMenu === item.name ? "link side-subnav-active" : "link"
          }
          // activeclassname="side-nav-active"
          onClick={() => {
            if (activeMenu === item.name) {
              showSubNav(!subnav);
            } else {
              showSubNav(true);
            }
            selectedMenu(item.name);
          }}
        >
          <div className="icon">{item.icon}</div>
          <div
            style={{ display: isMenuExpand ? "none" : "block" }}
            className="link_text"
          >
            {item.name}{" "}
            {item.subnav &&
              item.subnav.length > 0 &&
              (subnav && activeMenu === item.name ? (
                <FaAngleDown />
              ) : (
                <FaAngleRight />
              ))}
          </div>
        </NavLink>
      ) : (
        <NavLink
          to={item.path}
          className={activeMenu === item.name ? "link side-nav-active" : "link"}
          // activeclassname="side-nav-active"
          onClick={() => {
            showSubNav(false);
            selectedMenu(item.name);
          }}
        >
          <div className="icon">{item.icon}</div>
          <div
            style={{ display: isMenuExpand ? "none" : "block" }}
            className="link_text"
          >
            {item.name}{" "}
            {item.subnav &&
              item.subnav.length > 0 &&
              (subnav && activeMenu === item.name ? (
                <FaAngleDown />
              ) : (
                <FaAngleRight />
              ))}
          </div>
        </NavLink>
      )}

      <div className="subMenu">
        {subnav &&
          activeMenu === item.name &&
          item.subnav.map((subLink, subMenuIndex) => {
            if (checkUserAccess(subLink.roles)) {
              return (
                <NavLink
                  to={subLink.path}
                  // activeclassname="side-nav-active"
                  key={subMenuIndex}
                  className={
                    activeMenu === item.name
                      ? "link side-subnav-active"
                      : "link"
                  }
                  onClick={() => {}}
                >
                  <div className="icon">{subLink.icon}</div>

                  <div style={{ display: isMenuExpand ? "none" : "block" }}>
                    {subLink.name}{" "}
                  </div>
                </NavLink>
              );
            }
          })}
      </div>
    </>
  );
};

export default SidebarLink;
