import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listAction,
  storeListAction,
} from "../../network/store/action/ProductResponseAction";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";
import GMCTPagination from "../../components/pagination";
import ImageUpload from "../../components/image-upload";
import "./products.css";
import { useNavigate } from "react-router-dom";
import ProductListItemTwo from "./product-list-item";
import {
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
} from "../../redux/slice/formUpdateSlice";
import { isDsplayAddProduct, isStoreLogin } from "../../utils/userRoles";

function ProductTwo() {
  // to get api data

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  const imageUpload = useSelector(imageUploadStatus);
  const formData = useSelector(formUpdateData);

  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [productAddForm, setproductAddForm] = useState({
    sectionId: "",
    categoryId: "",
    storeId: 0,
  });

  const [isPriceUpdate, setIsPriceUpdate] = useState(false);
  const [isProductUpdate, setIsProductUpdate] = useState(false);

  useEffect(() => {
    getSections();
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId
    );
  }, []);

  useEffect(() => {
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId
    );
  }, [pageLimit, pageOffset, productAddForm, isPriceUpdate, isProductUpdate]);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      console.log("sectionList", response.result);
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const getProducts = async (limit, offset, sectionId, categoryId, storeId) => {
    if (!isDsplayAddProduct()) {
      storeId = localStorage.getItem("orgId");
    }

    if (isStoreLogin()) {
      await dispatch(
        storeListAction(
          {
            limit: limit,
            offset: offset,
            sectionId: sectionId,
            categoryId: categoryId,
            storeId: storeId,
          },
          dispatch
        )
      ).then((reponse) => {
        if (reponse.result) {
          setResultList(reponse.result);
          setTotalCount(Number(reponse.itemCount.totalFilterCount));
          setPageCount(reponse.itemCount.pageCount);
        } else {
          resetFilter();
        }
      });
    } else {
      await dispatch(
        listAction(
          {
            limit: limit,
            offset: offset,
            sectionId: sectionId,
            categoryId: categoryId,
            storeId: storeId,
          },
          dispatch
        )
      ).then((reponse) => {
        if (reponse.result) {
          setResultList(reponse.result);
          setTotalCount(Number(reponse.itemCount.totalFilterCount));
          setPageCount(reponse.itemCount.pageCount);
        } else {
          resetFilter();
        }
      });
    }
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);
    if (e.target.name === "sectionId") {
      const sectionIdex = sectionList.findIndex(
        (obj) => obj.id === e.target.value
      );
      setCategoryList(sectionList[sectionIdex].categories);
      setproductAddForm({
        ...productAddForm,
        categoryId: "",
        sectionId: e.target.value,
      });
    } else {
      setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
    }
    //  getProducts(pageLimit,pageOffset, e.target.name === 'sectionId'?e.target.value : '', e.target.name === 'categoryId'?e.target.value : '');
    //  setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value })
  };

  return (
    <>
      {/* {showRequestForm && <PriceEditForm />} */}
      {imageUpload && (
        <ImageUpload
          product={formData}
          onCancelClick={(reloadStatus) => {
            setIsProductUpdate(reloadStatus);
          }}
        />
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Products</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {isDsplayAddProduct() && (
                <button
                  type="button"
                  className="mr-auto btn btn-primary"
                  onClick={() => navigate("/cuprdct")}
                >
                  + Add Product
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search Product</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={""}
                placeholder="Enter Product name"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Section</label>
              <select
                name="sectionId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.sectionId}
              >
                <option value={""}>{"--Select Section--"}</option>
                {sectionList &&
                  sectionList.length > 0 &&
                  sectionList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="categoryId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.categoryId}
              >
                <option value={""}>{"--Select Category--"}</option>
                {categoryList &&
                  categoryList.length > 0 &&
                  categoryList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {isDsplayAddProduct() && (
            <div className="col-md-3">
              <div className="form-group">
                <label>Select Store Prices</label>
                <select
                  name="storeId"
                  className="form-select"
                  onChange={(e) => handleChanges(e)}
                  value={productAddForm.storeId}
                >
                  <option value={""}>{"--Select Category--"}</option>
                  <option value={0}>{"None"}</option>
                  return(
                  <option value={1}>{"Kavali"}</option>
                  <option value={2}>{"Anaparthi"}</option>)
                </select>
              </div>
            </div>
          )}

          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Preview Image</th>
                  <th scope="col">HSN Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Varients</th>
                  <th scope="col">Status</th>
                  {isDsplayAddProduct() && <th scope="col">Action</th>}
                </tr>
              </thead>
              {resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <ProductListItemTwo product={item} key={item.id} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
            <GMCTPagination
              pageCount={pageCount}
              activePage={activePage}
              totalCount={totalCount}
              onPageChange={(page) => handlePageChange(page)}
              onLimitChange={(e) => handleLimitChange(e)}
            />
          </div>
        </div>
      </main>
    </>
  );
}
export default ProductTwo;
