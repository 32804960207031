import { useState, memo } from "react";
import { FaEdit, FaImage, FaPlus, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../utils/userRoles";

const ProductListItemTwo = (props) => {
  const [product] = useState(props.product);
  const [itemStatus, setitemStatus] = useState(product.item.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editItem = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/udprdct");
  };

  const updatePriceItem = (item) => {
    dispatch(updateFormInfo({ displayForm: false, formData: { item } }));
    navigate("/udprice");
  };

  const onImageClick = () => {
    if (isDsplayAddProduct())
      dispatch(updateFormInfo({ imageUpload: true, formData: { product } }));
  };

  const addPriceItem = () => {
    dispatch(
      updateFormInfo({
        displayForm: true,
        formData: { product },
      })
    );
    navigate("/crprice");
  };

  const getStockLable = (stock) => {
    if (stock < 1) {
      return <span className="badge bg-danger">{stock}</span>;
    } else if (stock < 10) {
      return <span className="badge bg-warning">{stock}</span>;
    } else if (stock >= 10) {
      return <span className="badge bg-success">{stock}</span>;
    }
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (product.item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: product.item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        product.item.status = status;
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{product.item.id}</p>
      </td>

      <td className="align-middle">
        <div className="" onClick={() => onImageClick()}>
          {product.item.preview_image_path &&
          product.item.preview_image_path !== "" ? (
            <img
              src={product.item.preview_image_path}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>

      <td className="align-middle">
        <p>{product.item.hsn}</p>
      </td>
      <td className="align-middle">
        <p>{product.item.name}</p>
      </td>
      {/* <td className="align-middle">
        <p>{product.item.brand}</p>
      </td> */}

      <td className="align-middle">
        <div class="col-lg-12 d-flex justify-content-center">
          {product.prices && product.prices.length > 0 ? (
            product.prices.length == 1 ? (
              <>
                <div className="price-box">
                  <span
                    className="float-edit-btn"
                    onClick={() => {
                      updatePriceItem(product.prices[0]);
                    }}
                  >
                    <FaEdit />
                  </span>
                  <span>
                    {product.prices[0].qty} {product.prices[0].measure}{" "}
                    {getStockLable(product.prices[0].stock_quantity)}
                  </span>
                  <br />
                  <span>
                    {"SP: "}
                    {product.prices[0].offer_price !== "" ? (
                      <>
                        <b>{"₹" + product.prices[0].offer_price}</b>{" "}
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "grey",
                          }}
                        >
                          {"₹" + product.prices[0].price}
                        </span>
                      </>
                    ) : (
                      <b>{"₹" + product.prices[0].price}</b>
                    )}{" "}
                  </span>
                  <br />
                  <span>{"PP: ₹" + product.prices[0].purchase_price}</span>
                  <br />
                </div>
              </>
            ) : (
              <>
                <span
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#02a612",
                    color: "#ffff",
                    borderRadius: "20px",
                    fontSize: ".7rem",
                    maxWidth: "100px",
                  }}
                >
                  {product.prices.length} Variants
                </span>
              </>
            )
          ) : (
            <>
              <span> No Variants </span>
            </>
          )}
        </div>
      </td>
      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch  col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
      {isDsplayAddProduct() && (
        <td className="align-middle">
          <p className="col-lg-12 d-flex justify-content-center">
            <span
              className="edit-icon-btn"
              onClick={() => {
                editItem();
              }}
            >
              <FaRegEdit />
            </span>

            <span
              className="edit-rupee-btn"
              onClick={() => {
                addPriceItem();
              }}
            >
              <FaIndianRupeeSign />
            </span>

            <span className="delete-icon-btn">
              <FaTrash />
            </span>

            {/* <div className="add-btn-box position-rproductative">
            <span
              className="price-add-btn"
              onClick={() => {
                addPriceItem();
              }}
            >
              <FaPlus />
            </span>
          </div> */}
          </p>
        </td>
      )}
    </tr>
  );
};

export default memo(ProductListItemTwo);
